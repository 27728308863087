import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthCallBack = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code'); // Get the authorization code

    if (code) {
      // Use the authorization code to request tokens
      fetch('http://localhost:8888/.netlify/functions/authenticate?code=' + code)
        .then(response => response.json())
        .then(data => {
          console.log('Authentication success:', data);
          // Handle successful login (e.g., redirect to home page)
          navigate('/');
        })
        .catch(error => {
          console.error('Authentication error:', error);
        });
    }
  }, [navigate]);

  return (
    <div>
      <h1>Loading...</h1>
    </div>
  );
};

export default AuthCallBack;

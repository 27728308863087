import {
    Box,
    HStack,
    Icon,
    Image,
    Link,
    Stack,
    Text,
    useColorModeValue as mode,
  } from '@chakra-ui/react'
  import { FiGift } from 'react-icons/fi'
  import './cart.css';
  
  export const CartProductMeta = (props) => {
    const { isGiftWrapping = true, image, name, description } = props
    return (
      <Stack direction="row" spacing="5" width="full" className='product-info-cart'>
        <Image
          rounded="lg"
          width="120px"
          height="120px"
          fit="cover"
          src={image}
          alt={name}
          draggable="false"
          loading="lazy"
        />
        <Box pt="4">
          <Stack spacing="0.5" >
            <Text fontWeight="medium" className='cart-product-data'>{name}</Text>
            {/* <Text color={mode('gray.600', 'gray.400')} fontSize="sm" className='cart-product-data'>
              {description}
            </Text> */}
          </Stack>
          {/* {isGiftWrapping && (
            <HStack spacing="1" mt="3" color={mode('gray.600', 'gray.400')}>
              <Icon as={FiGift} boxSize="4" />
              <Link fontSize="sm" textDecoration="underline" className='cart-product-data'>
                Add gift wrapping
              </Link>
            </HStack>
          )} */}
        </Box>
      </Stack>
    )
  }
  
import { useToast } from '@chakra-ui/react';
import { useState, useEffect } from 'react';

// Create a custom context
import { createContext, useContext } from 'react';

// Create a CartContext
const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const toast = useToast();
  const [cartData, setCartData] = useState([]);

  useEffect(() => {
    const savedCart = localStorage.getItem('cartData');
    if (savedCart) {
      setCartData(JSON.parse(savedCart));
    }
  }, []);

  const saveCartData = (newCartData) => {
    localStorage.setItem('cartData', JSON.stringify(newCartData));
    setCartData(newCartData);
  };

  const parsePrice = (price) => {
    if (typeof price === 'string') {
      // Remove any non-numeric characters (e.g., ₹ symbol)
      return parseFloat(price.replace(/[^\d.-]/g, ''));
    }
    return price;
  };

  const addToCart = (newProduct) => {
    const parsedProduct = {
      ...newProduct,
      price: parsePrice(newProduct.price), // Convert price to number
    };

    const existingProduct = cartData.find((item) => item.id === parsedProduct.id);

    if (existingProduct) {
      const updatedCart = cartData.map((item) =>
        item.id === parsedProduct.id ? { ...item, quantity: item.quantity + 1 } : item
      );
      saveCartData(updatedCart);
    } else {
      const updatedCart = [...cartData, { ...parsedProduct, quantity: 1 }];
      saveCartData(updatedCart);
    }

    toast({
      title: `${newProduct.pname} added to cart!`,
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  };

  const updateQuantity = (productId, newQuantity) => {
    const updatedCart = cartData.map(item =>
      item.id === productId ? { ...item, quantity: newQuantity } : item
    );
    saveCartData(updatedCart);
    toast({
      title: 'Quantity updated!',
      status: 'info',
      duration: 2000,
      isClosable: true,
    });
  };

  const removeFromCart = (productId) => {
    const updatedCart = cartData.filter(item => item.id !== productId);
    saveCartData(updatedCart);
    toast({
      title: 'Product removed from cart!',
      status: 'warning',
      duration: 2000,
      isClosable: true,
    });
  };

  const getCartTotal = () => {
    return cartData.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  return (
    <CartContext.Provider value={{ addToCart, removeFromCart, cartData, getCartTotal, updateQuantity }}>
      {children}
    </CartContext.Provider>
  );
};

// Custom hook to use cart context
export const useCart = () => {
  return useContext(CartContext);
};

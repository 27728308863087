import React from 'react';
import './DottedBorder.css';

const DottedBorder = () => {
  return (
    <div className='div-area'>
        
    </div>
  );
}

export default DottedBorder;

import {
  Box,
  Flex,
  HStack,
  Heading,
  Link,
  Stack,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import { CartItem } from './CartItem';
import { CartOrderSummary } from './CartOrderSummary';
import './cart.css';
import { useCart } from '../../pages/ProductDescription/UseCart';
import { useNavigate } from 'react-router-dom';

export const MainCart = () => {
  const { cartData } = useCart();
  const navigate = useNavigate();
  const navigateTo=(path)=>{
    window.scrollTo({ top: 0});
    navigate(path);
}

  return (
    <Box
      maxW={{ base: '3xl', lg: '7xl' }}
      mx="auto"
      px={{ base: '4', md: '8', lg: '12' }}
      py={{ base: '6', md: '8', lg: '12' }}
      className="cart-main-content"
    >
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        align={{ lg: 'flex-start' }}
        spacing={{ base: '8', md: '16' }}
      >
        <Stack spacing={{ base: '8', md: '10' }} flex="2">
          <Heading fontWeight="extrabold" className="cart-header">
            Shopping Cart
          </Heading>

          <Stack spacing="6">
            {cartData && cartData.length > 0 ? (
              cartData.map((item) => (
                <CartItem key={item.id} {...item} className="cart-item-font" />
              ))
            ) : (
              <div>No items in the cart</div>
            )}
          </Stack>
        </Stack>

        {/* Conditionally render the Order Summary only if there are items in the cart */}
        {cartData && cartData.length > 0 && (
          <Flex direction="column" align="center" flex="1">
            <CartOrderSummary />
            <HStack mt="6" fontWeight="semibold">
              <p>or</p>
              <Link
                color={mode('blue.500', 'blue.200')}
                className="continue-shopping-option"
                onClick={()=>navigateTo('/')}
              >
                Continue shopping
              </Link>
            </HStack>
          </Flex>
        )}
      </Stack>
    </Box>
  );
};

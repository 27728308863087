import React from 'react';
import './Cart.css';
import Navbar from '../../components/Navbar/Navbar';
import ContactUs from '../../components/ContactUs/ContactUs';
import {MainCart} from '../../components/Cart/MainCart';
const Cart = () => {
  return (
    <div className='cart-main-container'>
      <Navbar/>
      <div className="cart-container">
        <MainCart/>
      </div>
      <ContactUs/>
    </div>
  )
}

export default Cart;
